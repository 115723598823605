import React, { useRef } from 'react';
import classnames from 'classnames';
import { Search, X } from 'react-feather';

import { Maybe } from '../../../graphql.schema';

import * as styles from './Input.module.scss';

export type InputProps = {
  type?: 'primary' | 'secondary';
  hideIcon?: boolean;
  value?: string;
  openInNewWindow?: Maybe<boolean> | undefined;
  handleSearch: (e: any) => void;
  onClearSearch: () => void;
} & React.HTMLProps<HTMLAnchorElement>;

const Input: React.FC<InputProps> = ({ placeholder, handleSearch, onClearSearch }) => {
  const myContainer = useRef(null);

  return (
    <div className={classnames(styles.input,"control")}>
      <span className={classnames(styles.leftIcon, "icon is-small")}>
        <Search color={classnames('black-5')} size={16} />
      </span>
      <input
        id="wse-school-search-input"
        onChange={handleSearch}
        className={classnames('input')}
        type="text"
        placeholder={placeholder}
        ref={myContainer}
      />
      <span onClick={() => {
        if(myContainer.current){
          myContainer.current.value = '';
        }
        onClearSearch();
      }}
        className={classnames(styles.rightIcon, "icon is-small")}>
        <X color={classnames('black-5')} size={20} />
      </span>
    </div>
  );
};

export default Input;
